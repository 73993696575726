<template>
  <div class="flex flex-col absolute left-0 top-0 justify-center items-center h-screen w-screen">
    <h1 class="text-5xl font-bold text-primary-dark">404 not found</h1>
    <h2 class="text-2xl font-bold mt-3">This page doesn't exist, go back</h2>
    <Button  class="mt-6" label="Back" @click="() => $router.back()"/>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import { Button } from '../../lib/mavejs'

@Component({
  components: { Button }
})
export default class NotFound extends Vue {

}
</script>
